$(document).on('turbolinks:load', function() {
  $("#open-search").click(function() {
    $(".search-form, .input").addClass("active");
    $("input[type='search']").focus();
    $(".sticky-footer").click(function() {
      $(".search-form, .input").removeClass("active");
      $("#header_filter_events").hide();
    });
  });
});

