$(document).on('turbolinks:load', function() {
  function hideFlashMessage(setTime){
    let time = 0
    $($('.alert').get().reverse()).each(function() {
      time = time + setTime
      $(this).delay(time).fadeOut(300);
    });
  }

  hideFlashMessage(2500)
});