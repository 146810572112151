$(document).on('turbolinks:load', function () {
  $("#contact_phone").on('keyup keypress', function(e) {
    if (e.type==="keyup" && $(this).val().length !== 10) {
      console.log( $(this).val().length );
      $("#send_form").attr('disabled', 'disabled').addClass('disabled');
    }else if(e.type==="keyup"){
      console.log( $(this).val().length  );
      $("#send_form").removeAttr('disabled', 'disabled').removeClass('disabled');
    }

    if(e.type==="keypress" && $(this).val().length === 10) return false;
  });
});
