$(document).on('turbolinks:load', function () {
  if ($('body').hasClass('admin-events') && ($('body').hasClass('edit') || $('body').hasClass('new')) ){
    function initTinyMCE_description() {
      tinymce.remove();
      tinymce.init({
        selector: '#event_description',
        plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
        imagetools_cors_hosts: ['picsum.photos'],
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        autosave_ask_before_unload: true,
        autosave_interval: '30s',
        autosave_prefix: '{path}{query}-{id}-',
        autosave_restore_when_empty: false,
        autosave_retention: '2m',
        image_advtab: true,
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        height: 600,
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link image imagetools table',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      });
    }

    initTinyMCE_description()
  }

  function validation_of_video_inpute(){
    $('input#event_video_1').keyup(function(){
      let video_1 = $('input#event_video_1')
      if(video_1.val().length >= 5) {
        let validated_string = video_1.val().replace('watch?v=', '').split("/");
        validated_string = validated_string[validated_string.length - 1]
        video_1.val('https://www.youtube.com/embed/' + validated_string);
      }
    });
    $('input#event_video_2').keyup(function(){
      let video_2 = $('input#event_video_2')
      if(video_2.val().length >= 5) {
        let validated_string = video_2.val().replace('watch?v=', '').split("/");
        validated_string = validated_string[validated_string.length - 1]
        video_2.val('https://www.youtube.com/embed/' + validated_string);
      }
    });
    $('input#event_video_3').keyup(function(){
      let video_3 = $('input#event_video_3')
      if(video_3.val().length >= 5) {
        let validated_string = video_3.val().replace('watch?v=', '').split("/");
        validated_string = validated_string[validated_string.length - 1]
        video_3.val('https://www.youtube.com/embed/' + validated_string);
      }
    });
  }

  validation_of_video_inpute()
});
